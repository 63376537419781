import { API_ADDRESS, views } from "../constants/general";
import { EDIT_QUERELLA, GET_CUARTELES, GET_QUERELLAS } from "../constants/action-types";
import { authGet, authPost } from "../utils/fetching";


export function editQuerella(isEditing, numQuerella) {
  return {
    type: EDIT_QUERELLA,
    payload: {
      view: isEditing ? views.tarjetaQuerella : views.querellas,
      numQuerellaEditing: isEditing ? numQuerella : null
    },
  };
}

export function getPatrulleros(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/get_patrulleros`)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso){
        return dispatch({ type: GET_CUARTELES, payload: {patrulleros: json.patrulleros} });
      }
    });
  }
}

export function getQuerellas(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/get_querellas`)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso){
        return dispatch({ type: GET_QUERELLAS, payload: {querellas: json.querellas} });
      }
    });
  }
}

export function addQuerella(payload) {
  return function(dispatch) {
    return authPost(`${API_ADDRESS}/create_edit_querella`, payload)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          dispatch(getQuerellas()),
          dispatch(editQuerella(true, json.numero_querella)),
        ])
      }
    })
  }
}
