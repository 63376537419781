export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const EDIT_QUERELLA = "EDIT_QUERELLA";
export const EDIT_UBICACIONES = "EDIT_UBICACIONES";
export const EDIT_USUARIOS = "EDIT_USUARIOS";
export const GET_SECTORES = "GET_SECTORES";
export const GET_CUARTELES = "GET_CUARTELES";
export const GET_COMANDANCIAS = "GET_COMANDANCIAS";
export const GET_PATRULLEROS = "GET_PATRULLEROS";
export const GET_ENUMS = "GET_ENUMS";
export const GET_QUERELLAS = "GET_QUERELLAS";
export const EDIT_PATRULLAS = "EDIT_PATRULLAS";
export const GET_PATRULLAS = "GET_PATRULLAS";
export const FULL_UPDATE = "FULL_UPDATE";
