import React, { Component } from 'react';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Dropdown from "./Dropdown";
import InteractiveTable from './InteractiveTable';

import { API_ADDRESS } from "../constants/general";
import { authPost } from "../utils/fetching";
import { editPatrullas } from "../actions/patrullas";
import { titleString } from "../utils/misc";



const mapStateToProps = state => {
  return {
    isEditing: state.isEditingPatrullas,
    cuarteles: state.cuarteles.map((cuartel) => ({label: cuartel.nombre, value: cuartel.numero_cuartel})),
  };
};


class ConnectedPatrullaEditor extends Component {

  render() {
    return (
      <div>
        <InteractiveTable
          data={query =>
              new Promise((resolve, reject) => {
                let postData = {
                  page: query.page + 1,
                  page_size: query.pageSize,
                  order_by: query.orderBy,
                  order_by_dir: query.orderDirection,
                  search: query.search
                }
                authPost(`${API_ADDRESS}/get_patrullas`, postData)
                .then(response => response.json())
                .then(json => {
                  if (json.exitoso) {
                    resolve({
                        data: json.patrullas,
                        page: json.page - 1,
                        totalCount: json.total_count,
                    });
                  } else {
                    reject()
                  }
                })
              })
          }
          title="Patrullas"
          label="Patrullas"
          columns={[
            {title: "Numero", field: "numero_patrulla"},
            {
              title: "Cuartel",
              field: "numero_cuartel",
              editComponent: props => (
                <Dropdown
                  choices={this.props.cuarteles}
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                />
              ),
              render: rowData => {
                let obj = this.props.cuarteles.find(o => o.value == rowData.numero_cuartel);
                return <Typography>{obj ? obj.label : "N/A"}</Typography>;
              }
            },
          ]}
          editable={{
            onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  authPost(`${API_ADDRESS}/new_patrulla`, newData)
                  .then(response => response.json())
                  .then(json => {
                    if (json.exitoso) {
                      resolve();
                    } else {
                      reject();
                    }
                });
              }),
            onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        {
                            /* let data = this.state.data;
                            const index = data.indexOf(oldData);
                            data.splice(index, 1);
                            this.setState({ data }, () => resolve()); */
                        }
                        resolve();
                    }, 1000);
                })
        }}
        />
      </div>
    );
  }
}

const PatrullaEditor = connect(
  mapStateToProps,
  null
)(ConnectedPatrullaEditor);

export default PatrullaEditor;
