import { API_ADDRESS, views } from "../constants/general";
import { FULL_UPDATE } from "../constants/action-types";
import { authGet, authPost } from "../utils/fetching";


export function editInforme(isEditing, numInforme) {
  return {
    type: FULL_UPDATE,
    payload: {
      view: isEditing ? views.editorInforme : views.informes,
      numInformeEditing: isEditing ? numInforme : null
    },
  };
}

export function getInformes(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/get_informes`)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso){
        return dispatch({ type: FULL_UPDATE, payload: {informes: json.informes} });
      }
    });
  }
}

export function addInforme(numero_querella, codigo_informe) {
  return function(dispatch) {
    return authPost(`${API_ADDRESS}/create_informe`, {numero_querella, codigo_informe})
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          dispatch(getInformes()),
          dispatch(editInforme(true, json.numero_informe)),
        ])
      }
    })
  }
}
