import React from 'react';
import { connect } from "react-redux";

import Typography from '@material-ui/core/Typography';

import Dropdown from "./Dropdown";
import InteractiveTable from "./InteractiveTable";
import { addSector, addCuartel, addComandancia, rmSector, rmCuartel, rmComandancia } from "../actions/ubicaciones";



const mapDataFieldToProps = field => state => {
  return {
    data: state[field],
    cuarteles: state.cuarteles.map((cuartel) => ({label: cuartel.nombre, value: cuartel.numero_cuartel})),
    comandancias: state.comandancias.map((o) => ({label: o.nombre, value: o.numero_comandancia})),
  };
};

const mapDispatchFieldToProps = (onRowAdd, onRowDelete) => dispatch => {
  return {
    editable: {
      onRowAdd: (newValue) => dispatch(onRowAdd(newValue)),
      onRowDelete: (oldValue) => dispatch(onRowDelete(oldValue)),
    }
  };
}

const ConnectedComandanciaTable = (pProps) => (
  <InteractiveTable
    title="Comandancias"
    label="Comandancias"
    {...pProps}
    columns={[
      {title: "Nombre", field: "nombre"},
      {title: "Número", field: "numero_comandancia"},
      {title: "ORI", field: "ori"}
    ]}
  />
);


const ConnectedCuartelTable = (pProps) => (
  <InteractiveTable
    title="Cuarteles"
    label="Cuarteles"
    {...pProps}
    columns={[
      {title: "Nombre", field: "nombre"},
      {title: "Número", field: "numero_cuartel"},
      {
        title: "Comandancia",
        field: "numero_comandancia",
        editComponent: props => (
          <Dropdown
            choices={pProps.comandancias}
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        render: rowData => {
          let obj = pProps.comandancias.find(o => o.value == rowData.numero_comandancia);
          return <Typography>{obj ? obj.label : "N/A"}</Typography>;
        }
      },
    ]}
  />
);

const ConnectedSectorTable = (pProps) => (
  <InteractiveTable
    title="Sectores"
    label="Sectores"
    {...pProps}
    columns={[
      {title: "Nombre", field: "nombre"},
      {
        title: "Cuartel",
        field: "numero_cuartel",
        editComponent: props => (
          <Dropdown
            choices={pProps.cuarteles}
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        render: rowData => {
          let obj = pProps.cuarteles.find(o => o.value == rowData.numero_cuartel);
          return <Typography>{obj ? obj.label : "N/A"}</Typography>;
        }
      },
      {title: "Código", field: "codigo"}
    ]}
  />
);

export const ComandanciaTable = connect(
  mapDataFieldToProps("comandancias"),
  mapDispatchFieldToProps(addComandancia, rmComandancia)
)(ConnectedComandanciaTable);

export const CuartelTable = connect(
  mapDataFieldToProps("cuarteles"),
  mapDispatchFieldToProps(addCuartel, rmCuartel)
)(ConnectedCuartelTable);

export const SectorTable = connect(
  mapDataFieldToProps("sectores"),
  mapDispatchFieldToProps(addSector, rmSector)
)(ConnectedSectorTable)