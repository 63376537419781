import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import grey from '@material-ui/core/colors/grey';

import { connect } from "react-redux";

import DocumentEditor from "./DocumentEditor";
import { API_ADDRESS } from "../constants/general";
import { authPost } from "../utils/fetching";
import { editInforme, getInformes } from "../actions/informes";
import { titleString } from "../utils/misc";

const divisionColor = grey[300];
const ButtonDivision = (props) => (
  <Grid container spacing={0}>
    <Box
      borderRight={1} borderLeft={1} borderTop={1} pl="2px" pr="2px" borderColor="grey.500"
      flexGrow={1} style={{ height: "100%"}} bgcolor={divisionColor}
    >
      <Grid  container alignItems="center">
        <Grid item xs={1} />
        <Grid item xs={10} justify="center" alignItems="center">
          <Typography align="center">{props.text}</Typography>
        </Grid>
      <Grid item alignItems="center" xs={1} justify="right" alightContent="flex-end" direction="column-reverse">
        <IconButton onClick={props.onClick} size="small" align="right"><AddIcon /></IconButton>
      </Grid>
      </Grid></Box>
  </Grid>
)

const LabeledDocument = (props) => (
  <Grid container spacing={0}>
    <Grid item xs={1}>
      <Box
      display="flex" 
        borderLeft={1}
        borderTop={1}
        pl="2px"
        pr="2px"
        borderColor="grey.500"
        flexGrow={1}
        style={{ height: "100%"}}
        alignItems="center" 
        justifyContent="center"
      >
        <IconButton onClick={props.onDelete} size="small"><DeleteIcon /></IconButton>
        {/* <Typography align="center">{props.label}</Typography> */}
      </Box>
    </Grid>
    <Grid item xs={11}>
      <DocumentEditor
        document={props.document}
        value={props.value}
        onChange={props.onChange}
        noBottom={props.noBottom}
      />
    </Grid>
  </Grid>
)

const mapStateToProps = state => {
  var supervisores = (state.patrulleros || []).map((patrullero) => (
    {
     label: `${patrullero.placa} ${patrullero.apellido}`,
     value: `${patrullero.placa} ${patrullero.apellido}`,
     es_supervisor: patrullero.es_supervisor,
    }
  ));
  supervisores = supervisores.filter((obj) => (obj.label != `${state.user.placa} ${state.user.apellido}`));
  supervisores = supervisores.filter((obj) => obj.es_supervisor);

  return {
    user: state.user,
    numero_informe: state.numInformeEditing,
    enums: state.enums,
    supervisores: supervisores,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editInforme: (isEditing, numInforme) => dispatch(editInforme(isEditing, numInforme)),
    getInformes: () => dispatch(getInformes()),
  };
}

class ConnectedInformeEditor extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleGuardar = this.handleGuardar.bind(this);
    this.handleRendir = this.handleRendir.bind(this);
    this.handleArrayChange = this.handleArrayChange.bind(this);
    this.handleArrayAdd = this.handleArrayAdd.bind(this);
    this.handleArrayDelete = this.handleArrayDelete.bind(this);
    this.state={
      numero_informe: this.props.numero_informe,
      tipo_informe: "inicial",
      querellante: "",
      sectores: []
    };
  }

  componentDidMount(oldProps) {
    authPost(`${API_ADDRESS}/get_informe_info`, {numero_informe: this.props.numero_informe})
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        this.setState(json.info);
        return json.info;
      }
    });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleArrayChange = (variable, key) => event => {
    const newValue = this.state[variable].map((item, i) => {
      if (i === key) {
        return Object.assign({}, item, { [event.target.id]: event.target.value })
      }
      return item
    })
    this.setState( {[variable]: newValue} );
  }

  handleArrayAdd = (variable) => () => {
    this.setState( {[variable]: this.state[variable].concat({})} );
  }

  handleArrayDelete = (variable, key) => () => {
    this.setState( {[variable]: this.state[variable].filter(
      (_, i) => (i !== key)
    )});
  }

  handleRendir(event) {
    authPost(`${API_ADDRESS}/edit_informe`, this.state)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        return authPost(`${API_ADDRESS}/rendir_informe`, {numero_informe: this.props.numero_informe})
      }
    })
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          this.props.getInformes(),
          this.props.editInforme(false),
        ])
      }
    })
  }

  handleGuardar(event) {
    authPost(`${API_ADDRESS}/edit_informe`, this.state)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          this.props.getInformes(),
          this.props.editInforme(false),
        ])
      }
    })
  }

  handleFirmar = firmar => event => {
    authPost(`${API_ADDRESS}/edit_informe`, this.state)
    .then(response => response.json())
    .then(json => {
      if (!json.exitoso) {
        throw 'No se pudieron guardar los cambios'
      }
    })
    .then(() =>
    authPost(
      `${API_ADDRESS}/aprobar_informe`,
      {numero_querella: this.props.numero_querella, aprobada: firmar}
    ))
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          this.props.getInformes(),
          this.props.editInforme(false),
        ])
      }
    })
  }


  render() {
    const actions = (
      <div>
        <Button onClick={this.handleGuardar} color="primary">
          Guardar
        </Button>
        <Button onClick={this.handleRendir} color="secondary">
          Rendir
        </Button>
      </div>
    )

    const documentoQuerellante = [
      [
        {value:"Informe de Incidente", label: "PPR-621.1", type: "text", readOnly: true},
        {var: "ori", label: "Núm. de ORI", type: "text", readOnly: true},
        {var: "numero_querella", label: "Núm. de Querella", type: "text", readOnly: true},
        {value: titleString(this.state.tipo_informe), label: "Tipo de Informe", type: "text", readOnly: true},
        {var: "sector", label: "Sector", type: "text", readOnly: true},
      ],
      [
        {var: "grupo_incidente", label: "Incidente", type: "select", choices: this.props.enums.GrupoIncidente},
        {var: "situacion_incidente", label: "Situación Del Incidente", type: "text",},
        {var: "medios_excepcionales", label: "Medios Excepcionales", type: "select", choices: this.props.enums.MediosExcepcionales},
      ],
      [{value: "Datos del Querellante", type: "division"}],
      [
        {var: "querellante", label: "Querellante", type: "text"},
        {var: "nombre_preferido", label: "Nombre Preferido", type: "text",},
        {var: "telefono_domiciliario", label: "Teléfono Domiciliario", type: "phone"},
      ],
      [
        {var: "direccion_recidencial", label: "Dirección Residencial", type: "text", width: 8},
        {var: "telefono_trabajo", label: "Teléfono trabajo", type: "phone"},
      ],
    ]
    const documentoGeneral = [
      [{value: "Motivación del Delito por Prejuicio", type: "division"}],
      [
        {var: "raza", label: "Raza", type: "select", choices: this.props.enums.Raza},
        {var: "religion", label: "Religión", type: "select", choices: this.props.enums.Religion},
        {var: "grupo_etnico", label: "Grupo Étnico", type: "select", choices: this.props.enums.GrupoEtnico},
        {var: "genero", label: "Género", type: "select", choices: this.props.enums.Genero},
      ],
      [
        {var: "orientacion_sexual", label: "Orientación Sexual", type: "select", choices: this.props.enums.OrientacionSexual},
        {var: "discapacidad", label: "Discapacidad", type: "select", choices: this.props.enums.Discapacidad},
        {var: "identidad_genero", label: "Identidad Género", type: "select", choices: this.props.enums.IdentidadGenero},
        {var: "otros", label: "Otros", type: "select", choices: this.props.enums.Otros},
      ],
      [{value: "Datos del Delito", type: "division"}],
      [
        {var: "delincuente_uso", label: "Delicuente Uso", type: "select", choices: this.props.enums.DelincuenteUso},
        {var: "locales_allanados", label: "Núm. Locales Allanados", type: "number",},
        {var: "metodo_entrada", label: "Método Entrada", type: "select", choices: this.props.enums.MetodoEntrada},
      ],
      [
        {var: "tipo_actividad_criminal", width: 6, label: "Tipo de Actividad Criminal Uso", type: "select", choices: this.props.enums.TipoActividadCriminal},
        {var: "hurto_cargamento", label: "Hurto Cargamento", type: "select", choices: this.props.enums.HurtoCargamento},
        {var: "tipo_pandilla", label: "Tipo de Pandilla", type: "select", choices: this.props.enums.TipoPandilla},
      ],
      [{value: "Datos del Agente", type: "division"}],
      [
        {var: "muerto_cumplimiento", label: "Caso De Agente del Orden Público Muerto en Cumplimiento del Deber", type: "select", choices: this.props.enums.MuertoCumplimiento},
        {var: "asignacion_funciones", label: "Tipo de Asignación de Funciones al Agente", type: "select", choices: this.props.enums.AsignacionFunciones},
      ],
      [
        {var: "tipo_arma", label: "Tipo De Arma", type: "select", choices: this.props.enums.TipoArma},
        {var: "arma_automatica", label: "Arma Automática", type: "check"},
      ],
    ]

    const documentoVictima = [
      [
        {var: "nombre", label: "Nombre", type: "text"},
        {var: "nombre_preferido", label: "Nombre Preferido", type: "text",},
        {var: "telefono", label: "Teléfono Domiciliario", type: "phone"},
      ],
      [
        {var: "direccion", label: "Dirección", type: "text"},
      ],
      [
        {var: "tipo_victima", label: "Tipo de Víctima", type: "select", choices: this.props.enums.TipoVictima, width: 3},
        {var: "raza", label: "Raza", type: "select", choices: this.props.enums.Raza},
        {var: "sexo", label: "Sexo", type: "select", choices: this.props.enums.Sexo},
        {var: "condicion_residencia", label: "Condición de Residencia", type: "select", choices: this.props.enums.CondicionResidencia, width: 3},
        {var: "grupo_etnico", label: "Grupo Étnico", type: "select", choices: this.props.enums.GrupoEtnico},
      ],
      [
        {var: "relacionado_numero_delito", label: "Relacionado con Núm. de delito arriba", type: "number",},
        {var: "fecha_nacimiento", label: "Fecha de Nacimiento", type: "date",},
        {var: "edad", label: "Edad", type: "number",},
        {var: "numero_victima", label: "Núm de Víctima", type: "text",},
      ],
      [
        {var: "vestimenta", label: "Vestimenta", type: "text",},
      ],
      [
        {var: "marcas_cicatrices", label: "Marcas o Cicatrices", type: "text",},
      ],
      [
        {var: "circunstancias_agresion", label: "Circunstancias de Agresión Grave/Asesinato", type: "select", choices: this.props.enums.CircunstanciasAgresion, width: 6},
        {var: "homicidio_justificable", label: "Homicidio Justificable", type: "select", choices: this.props.enums.HomicidioJustificable},
        {var: "homicidio_negligente", label: "Homicidio Negligente", type: "select", choices: this.props.enums.HomicidioNegligente},
      ],
      [
        {var: "otras_circunstancias", label: "Otras Circunstancias Justificables", type: "select", choices: this.props.enums.OtrasCircunstancias},
        {var: "relacion_victima", label: "Relación de Víctima a Delincuente", type: "select", choices: this.props.enums.RelacionVictima},
        {var: "unidad_transporto", label: "Unidad que Transportó a la Víctima a Facilidad de Salud", type: "text"},
      ],
      [
        {var: "firma_victima_nego", label: "Firma Víctima se Negó a Recibir Tratamiento", type: "text"},
        {var: "firma_testigo_rehuso", label: "Testimgo se Rehusó Transporte/Tratamiento ", type: "text"},
      ],
      [
        {var: "institucion_salud", label: "Institución de Salud", type: "text"},
        {var: "medico_licencia", label: "Médico/Licencia", type: "text"},
      ],
    ]

    const documentoDelito = [
      [
        {var: "codigo_delito_ucr", label: "Código Delito UCR", type: "select", choices: this.props.enums.InformeRendido},
        {var: "ocurrencia_incidente", label: "Fecha/Hora de Ocurrencia", type: "datetime-local"},
        {var: "situacion_delito", label: "Situación del Delito", type: "select", choices: this.props.enums.SituacionDelito},
        {var: "lugar_delito", label: "Lugar del Delito", type: "select", choices: this.props.enums.LugarDelito},
      ],
    ]

    const documentoBien = [
      [
        {var: "codigo", label: "Código", type: "select", choices: this.props.enums.CodigoBienes},
        {var: "cantidad", label: "Cantidad", type: "number"},
        {var: "valor", label: "Valor", type: "number"},
        {var: "fecha_recuperacion", label: "Fecha en que se recuperó", type: "date", width: 3},
        {var: "tipo_danos", label: "Tipo de Daños", type: "select", choices: this.props.enums.DanosBienes},
      ],
      [
        {var: "descripcion", label: "Descripción de Bienes (Incluir marca, modelo, tamaño, tipo, Núm. de serie, color, etc.)", type: "text",},
      ],
    ]

    const documentoSospechoso = [
      [
        {var: "nombre", label: "Nombre", type: "text",},
        {var: "direccion", label: "Dirección ", type: "text",},
      ],
      [
        {var: "edad", label: "Edad", type: "number"},
        {var: "sexo", label: "Sexo", type: "select", choices: this.props.enums.Sexo},
        {var: "raza", label: "Raza", type: "select", choices: this.props.enums.Raza},
        {var: "numero_sospechoso", label: "Núm. de Sospechoso", type: "number"},
      ],
      [
        {var: "vestimenta", label: "Vestimenta", type: "text",},
      ]
    ]

    const documentoDetenido = [
      [
        {var: "numero_detenidos", label: "Núm. Detenidos", type: "number",},
        {var: "fianza", label: "Fianza", type: "select", choices: this.props.enums.Fianza},
        {var: "cantidad", label: "Cantidad", type: "number"},
        {var: "uso_fuerza", label: "Uso Fuerza", type: "select", choices: this.props.enums.UsoFuerza},
        {var: "nivel_uso_fuerza", label: "Nivel de Fuerza", type: "select", choices: this.props.enums.NivelUsoFuerza},

      ],
      [
        {var: "nombre_fiscal", label: "Nombre Fiscal", type: "text"},
        {var: "nombre_juez", label: "Nombre Juez", type: "text"},
      ],
      [
        {var: "institucion_penal", label: "Institución Penal", type: "text"},
        {var: "oficial_correccion", label: "Oficial Correción", type: "text"},
        {var: "indicador_esclarecimiento", label: "Indicador de Esclarecimiento Múltiple", type: "select", choices: this.props.enums.IndicadorEsclarecimiento},
      ],
      [
        {var: "nombre_detenido", label: "Nombre Detenido", type: "text"},
        {var: "nombre_preferido", label: "Nombre Preferido", type: "text"},
      ],
      [
        {var: "direccion", label: "Direccion", type: "text"},
      ],
      [
        {var: "edad", label: "Edad", type: "number"},
        {var: "raza", label: "Raza", type: "select", choices: this.props.enums.Raza},
        {var: "sexo", label: "Sexo", type: "select", choices: this.props.enums.Sexo},
        {var: "fecha_nacimiento", label: "Fecha de Nacimiento", type: "date"},
        {var: "securo_social", label: "Seguro Social", type: "number"},
        {var: "condicion_residencia", label: "Condición de Residencia", type: "select", choices: this.props.enums.CondicionResidencia},
        {var: "nacionalidad", label: "Nacionalidad", type: "text"},
      ],
      [
        {var: "armado_con", label: "Armado Con", type: "select", choices: this.props.enums.ArmadoCon},
        {var: "tipo_detencion", label: "Tipo Detención", type: "select", choices: this.props.enums.TipoDetencion},
        {var: "disposicion", label: "Disposición", type: "select", choices: this.props.enums.Disposicion},
        {var: "codigo_detencion_ucr", label: "Código Detención UCR", type: "select", choices: this.props.enums.InformeRendido},
      ],
      [
        {var: "estatura", label: "Edad", type: "number"},
        {var: "peso", label: "Peso", type: "number"},
        {var: "ojos", label: "Ojos", type: "text"},
        {var: "cabello", label: "Cabello", type: "text"},
        {var: "numero_detencion", label: "Núm. Detención", type: "number"},
        {var: "fecha_detencion", label: "Fecha Detención", type: "date"},
      ],
    ]

    const documentoTestigo = [
      [
        {var: "nombre", label: "Nombre", type: "text",},
        {var: "telefono_residencial", label: "Teléfono Residencial", type: "phone",},
        {var: "telefono_trabajo", label: "Teléfono Trabajo", type: "phone",},
      ],
      [
        {var: "direccion", label: "Dirección", type: "text",},
      ],
    ]

    const documentoNarrativo = [
      [{value: "Narrativo", type: "division"}],
      [{var: "narrativo", type: "text", rows: 20}]
    ];

    const delitos = (this.state.delitos || []).map((val, i)=> (
      <LabeledDocument
        label={i}
        onDelete={this.handleArrayDelete("delitos", i)}
        document={documentoDelito}
        value={val}
        onChange={this.handleArrayChange("delitos", i)}
        noBottom={true}
      />      
    ))

    const victimas = (this.state.victimas || []).map((val, i)=> (
      <LabeledDocument
        label={i}
        onDelete={this.handleArrayDelete("victimas", i)}
        document={documentoVictima}
        value={Object.assign({titulo: `Datos Sobre la Víctima ${i+1}`}, val)}
        onChange={this.handleArrayChange("victimas", i)}
        noBottom={true}
      />
    ))

    const bienes = (this.state.bienes || []).map((val, i)=> (
      <LabeledDocument
        label={i}
        onDelete={this.handleArrayDelete("bienes", i)}
        document={documentoBien}
        value={Object.assign({titulo: `Bien ${i+1}`}, val)}
        onChange={this.handleArrayChange("bienes", i)}
        noBottom={true}
      />
    ))

    const sospechosos = (this.state.sospechosos || []).map((val, i)=> (
      <LabeledDocument
        label={i}
        onDelete={this.handleArrayDelete("sospechosos", i)}
        document={documentoSospechoso}
        value={Object.assign({titulo: `Sospechoso ${i+1}`}, val)}
        onChange={this.handleArrayChange("sospechosos", i)}
        noBottom={true}
      />
    ))

    const detenidos = (this.state.detenidos || []).map((val, i)=> (
      <LabeledDocument
        label={i}
        onDelete={this.handleArrayDelete("detenidos", i)}
        document={documentoDetenido}
        value={Object.assign({titulo: `Detenido ${i+1}`}, val)}
        onChange={this.handleArrayChange("detenidos", i)}
        noBottom={true}
      />
    ))

    const testigos = (this.state.testigos || []).map((val, i)=> (
      <LabeledDocument
        label={i}
        onDelete={this.handleArrayDelete("testigos", i)}
        document={documentoTestigo}
        value={Object.assign({titulo: `Testigo ${i+1}`}, val)}
        onChange={this.handleArrayChange("testigos", i)}
        noBottom={true}
      />
    ))

    return (
      <Box margin={2}>
        <Typography variant="h6">Informe</Typography>
        <Box marginTop={4} />
        <DocumentEditor document={documentoQuerellante} value={this.state} onChange={this.handleChange} noBottom={true} />
        <ButtonDivision onClick={this.handleArrayAdd("delitos")} text="Datos Sobre el Delito" />
        {delitos}
        <DocumentEditor document={documentoGeneral} value={this.state} onChange={this.handleChange} noBottom={true} />
        <ButtonDivision onClick={this.handleArrayAdd("victimas")} text="Agregar Víctima" />
        {victimas}
        <ButtonDivision onClick={this.handleArrayAdd("bienes")} text="Agregar Bien" />
        {bienes}
        <ButtonDivision onClick={this.handleArrayAdd("sospechosos")} text="Agregar Sospechoso" />
        {sospechosos}
        <ButtonDivision onClick={this.handleArrayAdd("detenidos")} text="Agregar Detenido" />
        {detenidos}
        <ButtonDivision onClick={this.handleArrayAdd("testigos")} text="Agregar Testigo" />
        {testigos}
        <DocumentEditor document={documentoNarrativo} value={this.state} onChange={this.handleChange} />
        <Box marginTop={2} style={{textAlign: "right"}}>
          {actions}
        </Box>
      </Box>
    );
  }
}

const InformeEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedInformeEditor);

export default InformeEditor;
