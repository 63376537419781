import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

import { connect } from "react-redux";

import { addInforme, editInforme } from "../actions/informes";
import { titleString } from "../utils/misc";



function mapDispatchToProps(dispatch) {
  return {
    editInforme: (isEditing, numInforme) => dispatch(editInforme(isEditing, numInforme)),
    addInforme: (querella, informe) => dispatch(addInforme(querella, informe)),
  };
}

const mapStateToProps = state => {
  return {
    codigoInformes: (state.enums.CodigoInforme || []).map((codigo) => ({label: titleString(codigo), value: codigo})),
  };
};

function ConnectedNewInformeModal(props) {

  const options = props.codigoInformes.map((codigo)=>(
    <ListItem button onClick={()=>props.addInforme(props.querella, codigo.value)}>
      <Typography>{codigo.label}</Typography>
    </ListItem>
  ))
  
  return (
    <div>
      <Dialog open={props.isOpen} aria-labelledby="form-dialog-create">
        <DialogTitle id="form-dialog-create">Seleccione Informe a Llenar</DialogTitle>
        <DialogContent>
        <List>
          {options}
        </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="error">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const NewInformeModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedNewInformeModal);

export default NewInformeModal;
