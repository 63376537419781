export const ID_TOKEN = "id_token";
export const views = {
  querellas: 1,
  comandancias: 2,
  cuarteles: 3,
  sectores: 4,
  usuarios: 5,
  patrullas: 6,
  tarjetaQuerella: 7,
  informes: 8,
  editorInforme: 9,
};
export const API_ADDRESS = "https://querellas-api.unoceropr.com/external";
