import React, { Component } from 'react';
import { connect } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import Dropdown from "./Dropdown";
import InteractiveTable from './InteractiveTable';

import { API_ADDRESS } from "../constants/general";
import { authPost } from "../utils/fetching";
import { titleString } from "../utils/misc";



const mapStateToProps = state => {
  return {
    cuarteles: state.cuarteles.map((cuartel) => ({label: cuartel.nombre, value: cuartel.numero_cuartel})),
    rangos: (state.enums.Rango || []).map((v) => ({label: titleString(v), value: v})),
    divisiones: (state.enums.Division || []).map((v) => ({label: titleString(v), value: v})),
  };
};

class ConnectedUsuarioEditor extends Component {
  render() {
    return (
      <div>
        <InteractiveTable
          data={query =>
              new Promise((resolve, reject) => {
                let postData = {
                  page: query.page + 1,
                  page_size: query.pageSize,
                  order_by: query.orderBy,
                  order_by_dir: query.orderDirection,
                  search: query.search
                }
                authPost(`${API_ADDRESS}/get_users`, postData)
                .then(response => response.json())
                .then(json => {
                  if (json.exitoso) {
                    resolve({
                        data: json.users,
                        page: json.page - 1,
                        totalCount: json.total_count,
                    });
                  } else {
                    reject()
                  }
                })
              })
          }
          title="Usuarios"
          label="Usuarios"
          columns={[
            {title: "Placa", field: "placa"},
            {title: "Nombre", field: "nombre"},
            {title: "Apellido", field: "apellido"},
            {
              title: "Numero de cuartel",
              field: "numero_cuartel",
              editComponent: props => (
                <Dropdown
                  choices={this.props.cuarteles}
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                />
              ),
              render: rowData => {
                let obj = this.props.cuarteles.find(o => o.value == rowData.numero_cuartel);
                return <Typography>{obj ? obj.label : "N/A"}</Typography>;
              }
            },
            {
              title: "Rango",
              field: "rango",
              editComponent: props => (
                <Dropdown
                  choices={this.props.rangos}
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                />
              ),
              render: rowData => {
                let obj = this.props.rangos.find(o => o.value == rowData.rango);
                return <Typography>{obj ? obj.label : "N/A"}</Typography>;
              }
            },
            {title: "Email", field: "email"},
            {
              title: "Division",
              field: "division",
              editComponent: props => (
                <Dropdown
                  choices={this.props.divisiones}
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                />
              ),
              render: rowData => {
                let obj = this.props.divisiones.find(o => o.value == rowData.division);
                return <Typography>{obj ? obj.label : "N/A"}</Typography>;
              }
            },
            {
              title: "SuperUser",
              field: "is_superuser",
              editComponent: props => (
                <Checkbox
                  checked={props.value}
                  onChange={e => props.onChange(e.target.checked)}
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              ),
              render: rowData => (
                <Checkbox
                  checked={rowData.is_superuser}
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              ),
            },
          ]}
          editable={{
            onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  authPost(`${API_ADDRESS}/new_user`, newData)
                  .then(response => response.json())
                  .then(json => {
                    if (json.exitoso) {
                      resolve();
                    } else {
                      reject();
                    }
                });
              }),
            onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  authPost(`${API_ADDRESS}/update_user`, Object.assign({placa_actual: oldData.placa},newData))
                  .then(response => response.json())
                  .then(json => {
                    if (json.exitoso) {
                      resolve();
                    } else {
                      reject();
                    }
                  });
                }),
            onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        {
                            /* let data = this.state.data;
                            const index = data.indexOf(oldData);
                            data.splice(index, 1);
                            this.setState({ data }, () => resolve()); */
                        }
                        resolve();
                    }, 1000);
                })
        }}
        />
      </div>
    );
  }
}

const UsuarioEditor = connect(
  mapStateToProps,
  null
)(ConnectedUsuarioEditor);

export default UsuarioEditor;
