import React, { Component } from 'react';
import { connect } from "react-redux";

import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import GestureRoundedIcon from '@material-ui/icons/GestureRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';

import Dropdown from "./Dropdown";
import InteractiveTable from './InteractiveTable';
import NewInformeModal from './NewInformeModal';
import Suggest from "./Suggest";
import { editInforme } from "../actions/informes";
import { titleString } from "../utils/misc";



const mapStateToProps = state => {
  return {
    informes: state.informes ? state.informes : [],
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editInforme: (isEditing, numInforme) => dispatch(editInforme(isEditing, numInforme)),
  };
}

function ConnectedTablaInformes(props) {

  return (
    <div>
      <InteractiveTable
        title="Informes"
        label="Informes"
        data={props.informes}
        actions={[
          {
            icon: () => (<EditIcon />),
            tooltip: 'Editar Informe',
            onClick: (event, rowData) => props.editInforme(true, rowData.numero_informe),
          },
        ]}
        columns={[
          {title: "Num. Querella", field: "numero_querella"},
          {
            title: "Informe", field: "codigo_informe",
            render: rowData => {
              return <Typography>{titleString(rowData.codigo_informe)}</Typography>;
            },
          },
          {title: "Patrullero", field: "patrullero"},
          {
            title: "Estado", field: "estado",
            render: rowData => {
              return <Typography>{titleString(rowData.estado)}</Typography>;
            },
          }
        ]}
      />
    </div>
  );
};


const TablaInformes = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTablaInformes);

export default TablaInformes;
