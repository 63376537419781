import React from 'react';
import Grid from '@material-ui/core/Grid';


import SquareInput from "./SquareInput";
import { titleString } from "../utils/misc";

export default function DocumentEditor(props){

    const makeRow = (columns, isLast) => {
    const colLength = columns.length;
    var spaceLeft = 12;
    var inputs = [];
    for (var i = 0; i < colLength; i++) {
      let column = columns[i];
      const width =  Math.floor(spaceLeft/(colLength - i))
      const choices = (column.choices || []).map((x) => ({label: titleString(x), value: x}));
      inputs[i] = (
        <Grid item md={width} key={column.var}>
          <SquareInput
            id={column.var}
            label={column.label}
            value={column.value || props.value[column.var] || ""}
            onChange={props.onChange}
            type={column.type || "text"}
            readOnly={column.readOnly}
            borderRight={i+1 === colLength ? undefined : 0}
            borderBottom={(isLast && !props.noBottom) ? undefined : 0}
            rows={column.rows || 1}
            choices={choices}
          />
        </Grid>
      )
      spaceLeft -= width;
    }
    return (
      <Grid container spacing={0}>
        {inputs}
      </Grid>
    )
  }

  const rowLength = props.document.length;
  const rows = props.document.map((row, i) => (
    <Grid container spacing={0}>
      {makeRow(row, rowLength===i+1)}
    </Grid>
  ));

  return (<div>{rows}</div>)
}