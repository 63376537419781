import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BallotIcon from '@material-ui/icons/Ballot';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { logout, setView } from "../actions/index";
import { views } from "../constants/general";
import TablaQuerellas from './TablaQuerellas';
import { ComandanciaTable, CuartelTable, SectorTable } from './TablasUbicaciones';
import UsuarioEditor from './UsuarioEditor';
import PatrullaEditor from './PatrullaEditor';
import QuerellaEditor from './QuerellaEditor';
import TablaInformes from './TablaInformes';
import InformeEditor from './InformeEditor';


const drawerWidth = 60;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    margin: 30,
  },
  title: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  
}));

const mapStateToProps = state => {
  return {user: state.user, view: state.view };
};

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    setView: (view) => dispatch(setView(view))
  };
}

function ConnectedDashboard(props) {
  const classes = useStyles();
  const panels = {
    [views.querellas]: <TablaQuerellas />,
    [views.comandancias]: <ComandanciaTable />,
    [views.cuarteles]: <CuartelTable />,
    [views.sectores]: <SectorTable />,
    [views.usuarios]: <UsuarioEditor />,
    [views.patrullas]: <PatrullaEditor />,
    [views.tarjetaQuerella]: <QuerellaEditor />,
    [views.informes]: <TablaInformes />,
    [views.editorInforme]: <InformeEditor />,
  }
  const panel = panels[props.view]
  const [ubicacionAnchor, setUbicacionAnchor] = useState(null);
  const setFromMenu = (view) => Promise.all([
    setUbicacionAnchor(null),
    props.setView(view)
  ])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title} color="secondary">
            UnoCero
          </Typography>
          <Typography variant="h6" noWrap>
            {props.user.placa} {props.user.apellido}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div>
        <List>
          <ListItem button onClick={()=>props.setView(views.querellas)}>
            <ListItemIcon><Tooltip title="Querellas"><BallotIcon /></Tooltip></ListItemIcon>
          </ListItem>
          <ListItem button onClick={()=>props.setView(views.informes)}>
            <ListItemIcon><Tooltip title="Informes"><AssignmentIcon /></Tooltip></ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={(event)=>setUbicacionAnchor(event.currentTarget)} aria-controls="ubicacion-menu" aria-haspopup="true">
            <ListItemIcon><Tooltip title="Ubicaciones"><AddLocationIcon /></Tooltip></ListItemIcon>
          </ListItem>
          <Menu
            id="ubicacion-menu"
            anchorEl={ubicacionAnchor}
            keepMounted
            open={ubicacionAnchor!=null}
            onClose={()=>setUbicacionAnchor(null)}
          >
            <MenuItem onClick={()=>setFromMenu(views.comandancias)}>Comandancias</MenuItem>
            <MenuItem onClick={()=>setFromMenu(views.cuarteles)}>Cuarteles</MenuItem>
            <MenuItem onClick={()=>setFromMenu(views.sectores)}>Sectores</MenuItem>
          </Menu>
          <ListItem button onClick={()=>props.setView(views.usuarios)}>
            <ListItemIcon><Tooltip title="Usuarios"><PersonAddIcon /></Tooltip></ListItemIcon>
          </ListItem>
          <ListItem button onClick={()=>props.setView(views.patrullas)}>
            <ListItemIcon><Tooltip title="Patrullas"><LocalTaxiIcon /></Tooltip></ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={props.logout}>
            <ListItemIcon><Tooltip title="Logout"><ExitToAppIcon /></Tooltip></ListItemIcon>
          </ListItem>
        </List>
        </div>
      </Drawer>
      <main className={classes.title}>
        <div className={classes.toolbar} />
        <Box className={classes.content}>
          {panel}
        </Box>
      </main>
    </div>
  );
}

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(ConnectedDashboard);
export default Dashboard;