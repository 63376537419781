import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { connect } from "react-redux";

import SquareInput from "./SquareInput";
import { API_ADDRESS } from "../constants/general";
import { authPost } from "../utils/fetching";
import { editQuerella, getQuerellas } from "../actions/querellas";
import { titleString } from "../utils/misc";


const mapStateToProps = state => {
  var patrulleros = [];
  if (state.user.division != 'centro_de_mando') {
    patrulleros = [{label: `${state.user.placa} ${state.user.apellido}`, value: `${state.user.placa} ${state.user.apellido}`}];
  } else {
    patrulleros = (state.patrulleros || []).map((patrullero) => ({label: `${patrullero.placa} ${patrullero.apellido}`, value: `${patrullero.placa} ${patrullero.apellido}`}));
  }

  var supervisores = (state.patrulleros || []).map((patrullero) => (
    {
     label: `${patrullero.placa} ${patrullero.apellido}`,
     value: `${patrullero.placa} ${patrullero.apellido}`,
     es_supervisor: patrullero.es_supervisor,
    }
  ));
  supervisores = supervisores.filter((obj) => (obj.label != `${state.user.placa} ${state.user.apellido}`));
  supervisores = supervisores.filter((obj) => obj.es_supervisor);


  return {
    user: state.user,
    numero_querella: state.numQuerellaEditing,
    cuarteles: state.cuarteles.map((cuartel) => ({label: cuartel.nombre, value: cuartel.numero_cuartel})),
    patrullas: state.patrullas.map((patrulla) => ({label: patrulla.numero_patrulla, value: patrulla.numero_patrulla})),
    vehiculo_estados: (state.enums.VehiculoEstado || []).map((estado) => ({label: titleString(estado), value: estado})),
    vehiculo_marcas: (state.enums.VehiculoMarca || []).map((marca) => ({label: titleString(marca), value: marca})),
    vehiculo_colores: (state.enums.VehiculoColor || []).map((color) => ({label: titleString(color), value: color})),
    delito_cometidos: (state.enums.DelitoCometido || []).map((delito) => ({label: titleString(delito), value: delito})),
    informe_rendidos: (state.enums.InformeRendido || []).map((informe) => ({label: titleString(informe), value: informe})),
    patrulleros: patrulleros,
    supervisores: supervisores,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editQuerella: (isEditing, numQuerella) => dispatch(editQuerella(isEditing, numQuerella)),
    getQuerellas: () => dispatch(getQuerellas()),
  };
}

class ConnectedQuerellaEditor extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleGuardar = this.handleGuardar.bind(this);
    this.handleRendir = this.handleRendir.bind(this);
    this.makeInput = this.makeInput.bind(this);
    this.state={
      querellante: "",
      sectores: []
    };
  }

  componentDidMount(oldProps) {
    authPost(`${API_ADDRESS}/get_querella_info`, {numero_querella: this.props.numero_querella})
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        this.setState(json.info);
        return json.info;
      }
    })
    .then(info => authPost(`${API_ADDRESS}/get_sectores`, {numero_cuartel: info.cuartel}))
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        var sectores = json.sectores.map((sector) => ({label: `${sector.codigo} - ${sector.nombre}`, value: sector.codigo}));
        this.setState({sectores: sectores});
      }
    });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleRendir(event) {
    authPost(`${API_ADDRESS}/create_edit_querella`, this.state)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        return authPost(`${API_ADDRESS}/rendir_querella`, {numero_querella: this.props.numero_querella})
      }
    })
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          this.props.getQuerellas(),
          this.props.editQuerella(false),
        ])
      }
    })
  }

  handleGuardar(event) {
    authPost(`${API_ADDRESS}/create_edit_querella`, this.state)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          this.props.getQuerellas(),
          this.props.editQuerella(false),
        ])
      }
    })
  }

  handleFirmar = firmar => event => {
    authPost(`${API_ADDRESS}/create_edit_querella`, this.state)
    .then(response => response.json())
    .then(json => {
      if (!json.exitoso) {
        throw 'No se pudieron guardar los cambios'
      }
    })
    .then(() =>
    authPost(
      `${API_ADDRESS}/aprobar_querella`,
      {numero_querella: this.props.numero_querella, aprobada: firmar}
    ))
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          this.props.getQuerellas(),
          this.props.editQuerella(false),
        ])
      }
    })
  }

  makeInput(id, label, type, borderRight, borderBottom, readOnly, rows, choices) {
    return (
      <SquareInput
        id={id}
        label={label}
        value={this.state[id] || ""}
        onChange={this.handleChange}
        type={type}
        readOnly={readOnly}
        borderRight={borderRight}
        borderBottom={borderBottom}
        rows={rows || 1}
        choices={choices}
      />
    )
  }

  render() {
    var querella_id_comp = this.makeInput("numero_querella", "Núm Querella", "text", undefined, 0, true);
    if (!this.props.numero_querella){
      querella_id_comp = this.makeInput("numero_cuartel", "Cuartel", "select", undefined, 0, false, 1, this.props.cuarteles);
    }

    const es_supervisor_centro_mando = (
      this.props.user.rango != 'agente' &&
      this.props.user.rango != 'civil' &&
      this.props.user.rango != 'admin' &&
      this.props.user.division == 'centro_de_mando'
    );

    var actions = null;
    if (
      this.state.estado === 'rendida'
      && es_supervisor_centro_mando
      && this.state.patrullero != `${this.props.user.placa} ${this.props.user.apellido}`
    ) {
      actions = (
        <div>
          <Button onClick={this.handleFirmar(true)} color="primary">
            Firmar
          </Button>
          <Button onClick={this.handleFirmar(false)} color="secondary">
            Rechazar
          </Button>
        </div>
      )
    }
    else {
      actions = (
        <div>
          <Button onClick={this.handleGuardar} color="primary">
            Guardar
          </Button>
          <Button onClick={this.handleRendir} color="secondary">
            Rendir
          </Button>
        </div>
      )
    }

    return (
      <Box margin={2}>
        <Typography variant="h6">Tarjeta de Querella</Typography>
        <Box marginTop={4} />
        <Grid container spacing={0}>
          <Grid item md={2}>{this.makeInput("ocurrencia_hechos", "Ocurrencia Hechos", "datetime-local", 0, 0)}</Grid>
          <Grid item md={8}>{this.makeInput("querellante", "Querellante", "text", 0, 0)}</Grid>
          <Grid item md={2}>{querella_id_comp}</Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item md={2}>{this.makeInput("tiempo_recibida_policia", "Recibida por la Policía", "datetime-local", 0, 0, true)}</Grid>
          <Grid item md={6}>{this.makeInput("direccion_querellante", "Dirección del Querellante", "text", 0, 0)}</Grid>
          <Grid item md={2}>{this.makeInput("telefono_querellante", "Teléfono", "phone", 0, 0)}</Grid>
          <Grid item md={2}>{this.makeInput("sector_hechos", "Sector Hechos", "select", undefined, 0, false, 1, this.state.sectores)}</Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={2}>{this.makeInput("tiempo_transmitida_patrulla", "Transmitida a Patrulla", "datetime-local", 0, 0, true)}</Grid>
          <Grid item xs={8}>{this.makeInput("perjudicado", "Persona o Empresa Perjudicada", "text", 0, 0)}</Grid>
          <Grid item xs={2}>{this.makeInput("patrulla", "Patrulla Asignada", "suggest", undefined, 0, false, 1, this.props.patrullas)}</Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={2}>{this.makeInput("tiempo_llegada_escena", "Llegada a la Escena", "datetime-local", 0, 0, false, 1)}</Grid>
          <Grid item xs={4}>{this.makeInput("lugar_hechos", "Sitio de lo Hechos", "text", 0, 0, false, 3)}</Grid>
          <Grid item xs={4}>{this.makeInput("complemento_direccion", "Complemento Dirección", "text", 0, 0, false, 3)}</Grid>
          <Grid item xs={2}>{this.makeInput("informe_rendido", "Informe Rendido", "select", undefined, 0, false, 1, this.props.informe_rendidos)}</Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={2}>{this.makeInput("tiempo_terminada", "Terminada", "datetime-local", 0, 0, true)}</Grid>
          <Grid item xs={8}>{this.makeInput("hechos", "Hechos (Sea breve)", "text", 0, 0)}</Grid>
          <Grid item xs={2}>{this.makeInput("uso_fuerza", "Uso Fuerza", "check", undefined, 0)}</Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={2}>{this.makeInput("recepcionista", "Recepcionista", "text", 0, 0, true)}</Grid>
          <Grid container xs={8}>
            <Grid item xs={2}>{this.makeInput("vehiculo_estado", "Estado", "select", 0, 0, false, 1, this.props.vehiculo_estados)}</Grid>
            <Grid item xs={2}>{this.makeInput("vehiculo_marca", "Marca", "select", 0, 0, false, 1, this.props.vehiculo_marcas)}</Grid>
            <Grid item xs={1}>{this.makeInput("vehiculo_ano", "Año", "number", 0, 0)}</Grid>
            <Grid item xs={2}>{this.makeInput("vehiculo_color", "Color", "select", 0, 0, false, 1, this.props.vehiculo_colores)}</Grid>
            <Grid item xs={2}>{this.makeInput("vehiculo_tablilla", "Tablilla", "text", 0, 0)}</Grid>
            <Grid item xs={3}>{this.makeInput("vehiculo_vin", "Núm de Serie o Motor", "text", 0, 0)}</Grid>
          </Grid>
          <Grid item xs={2}>{this.makeInput("delito_cometido", "Delito Cometido", "select", undefined, 0, false, 1, this.props.delito_cometidos)}</Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={2}>{this.makeInput("operador", "Radio Operador", "text", 0, undefined, true)}</Grid>
          <Grid container xs={8}>
            <Grid item xs={4}>{this.makeInput("patrullero", "Patrullero", "suggest", 0, undefined, false, 1, this.props.patrulleros)}</Grid>
            <Grid item xs={4}>{this.makeInput("supervisor_turno_unidad", "Sup. Turno Unidad", "suggest", 0, undefined, false, 1, this.props.supervisores)}</Grid>
            <Grid item xs={4}>{this.makeInput("supervisor_centro_mando", "Sup. Centro de Mando", "text", 0, undefined, true)}</Grid>
          </Grid>
          <Grid item xs={2}>{this.makeInput("notificado_a", "Notificado A", "text", undefined, undefined, true)}</Grid>
        </Grid>
        <br />
        {this.makeInput("relato", "Observaciones", "text", undefined, undefined, false, 15)}
        <Box marginTop={2} style={{textAlign: "right"}}>
          {actions}
        </Box>
      </Box>
    );
  }
}

const QuerellaEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedQuerellaEditor);

export default QuerellaEditor;
