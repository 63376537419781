import React, { Component } from 'react';
import { connect } from "react-redux";

import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import GestureRoundedIcon from '@material-ui/icons/GestureRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';

import Dropdown from "./Dropdown";
import InteractiveTable from './InteractiveTable';
import NewInformeModal from './NewInformeModal';
import Suggest from "./Suggest";
import { addQuerella, editQuerella } from "../actions/querellas";
import { titleString } from "../utils/misc";



const mapStateToProps = state => {
  var patrulleros = [];
  if (state.user.division != 'centro_de_mando') {
    patrulleros = [{label: `${state.user.placa} ${state.user.apellido}`, value: state.user.placa}]
  } else {
    patrulleros = (state.patrulleros || []).map((patrullero) => ({label: `${patrullero.placa} ${patrullero.apellido}`, value: patrullero.placa}))
  }

  return {
    querellas: state.querellas ? state.querellas : [],
    user: state.user,
    cuarteles: state.cuarteles.map((cuartel) => ({label: cuartel.nombre, value: cuartel.numero_cuartel})),
    patrulleros: patrulleros,
    delito_cometidos: (state.enums.DelitoCometido || []).map((delito) => ({label: titleString(delito), value: delito})),
    prioridades: (state.enums.Prioridad || []).map((prioridad) => ({label: titleString(prioridad), value: prioridad})),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addQuerella: (payload) => dispatch(addQuerella(payload)),
    editQuerella: (isEditing, numQuerella) => dispatch(editQuerella(isEditing, numQuerella)),
  };
}

class ConnectedTableQuerellas extends Component {
  constructor(props) {
    super(props);
    this.state={
      isOpen: false,
      soloMias: false,
      soloEsperandoFirmas: false,
      informeAnchor: null,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleMisQuerellas = this.toggleMisQuerellas.bind(this);
    this.toggleEsperandoFirmas = this.toggleEsperandoFirmas.bind(this);
  }

  toggleMisQuerellas() {
    this.setState({soloMias: !this.state.soloMias})
  }

  toggleEsperandoFirmas() {
    this.setState({soloEsperandoFirmas: !this.state.soloEsperandoFirmas})
  }

  toggleModal(event) {
    this.setState({isOpen: !this.state.isOpen});
  }

  render () {
    const cuarteles = this.props.cuarteles;
    const delito_cometidos = this.props.delito_cometidos;
    const prioridades = this.props.prioridades;
    const patrulleros = this.props.patrulleros;

    var querellas = this.props.querellas.filter((querella) => {
      if (this.state.soloMias && querella.patrullero != `${this.props.user.placa} ${this.props.user.apellido}`) {
        return false;
      }
      if (this.state.soloEsperandoFirmas && querella.estado != 'rendida') {
        return false;
      }
      return true;
    }


    );

    return (
      <div>
        <InteractiveTable
          title="Querellas"
          label="Querellas"
          editable= {{
            onRowAdd: (newValue) => this.props.addQuerella(newValue)
          }}
          data={querellas}
          actions={[
            rowData => ({
              icon: () => (<EditIcon />),
              tooltip: 'Editar Querella',
              onClick: (event, rowData) => this.props.editQuerella(true, rowData.numero_querella),
              hidden: rowData.estado === 'firmada'
            }),
            rowData => ({
              icon: () => (<AssignmentIcon aria-controls="informe-menu" aria-haspopup="true"/>),
              tooltip: 'Comenzar Informe',
              onClick: (event, rowData) => this.setState({paraInforme: rowData.numero_querella}),
              hidden: rowData.estado !== 'firmada'
            }),
            {
              icon: () => (<PersonIcon color={this.state.soloMias ? 'secondary' : 'primary'} />),
              tooltip: 'Mis Querellas',
              onClick: this.toggleMisQuerellas,
              isFreeAction: true,
            },
            {
              icon: () => (<GestureRoundedIcon color={this.state.soloEsperandoFirmas ? 'secondary' : 'primary'} />),
              tooltip: 'Esperando Firma',
              onClick: this.toggleEsperandoFirmas,
              isFreeAction: true,
            },
          ]}
          columns={[
            {title: "Estado", field: "estado", editable: 'never',
             render: rowData => (<Typography>{rowData ? titleString(rowData.estado) : "N/A"} </Typography>),
            },
            {
              title: "Cuartel", field: "numero_cuartel",
              editComponent: props => (
                <Dropdown
                  choices={cuarteles}
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                />
              ),
              render: rowData => {
                let obj = this.props.cuarteles.find(o => o.value == rowData.numero_cuartel);
                return <Typography>{obj ? obj.label : "N/A"}</Typography>;
              },
            },
            {title: "Num. Querella", field: "numero_querella", editable: 'never'},
            {
              title: "Delito", field: "delito_cometido",
              editComponent: props => (
                <Dropdown
                  choices={delito_cometidos}
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                />
              ),
              render: rowData => (<Typography>{titleString(rowData ? rowData.delito_cometido : "N/A")} </Typography>),
            },
            {
              title: "Prioridad", field: "prioridad",
              editComponent: props => (
                <Dropdown
                  choices={prioridades}
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                />
              ),
               render: rowData => (<Typography>{titleString(rowData ? rowData.prioridad : "N/A")} </Typography>),
            },
            {title: "Lugar Hechos", field: "lugar_hechos"},
            {
              title: "Patrullero", field: "patrullero",
              editComponent: props => (
                <Suggest
                  choices={patrulleros}
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                />
              )
            }
          ]}
        />
        <NewInformeModal
          isOpen={(this.state.paraInforme != null)}
          querella={this.state.paraInforme}
          close={()=>this.setState({paraInforme: null})}
        />
        </div>
      )
    }
};


const TablaQuerellas = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTableQuerellas);

export default TablaQuerellas;
