import { API_ADDRESS } from "../constants/general";
import { EDIT_PATRULLAS, GET_PATRULLAS } from "../constants/action-types";
import { authGet, authPost } from "../utils/fetching";


export function editPatrullas(isEditing) {
  return {
    type: EDIT_PATRULLAS,
    payload: {isEditingPatrullas: isEditing},
  };
}

export function getPatrullas(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/get_patrullas`)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso){
        return dispatch({ type: GET_PATRULLAS, payload: {patrullas: json.patrullas} });
      }
    });
  }
};
